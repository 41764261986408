<template>
  <div id="EnterpriseServices">
    <div class="result-panel">
      <CSTable>
        <template v-slot:header>
          <div class="table-header-panel text-right">
            <button
                class="btn btn-primary sticky-right"
                @click="addEnterpriseServices"
            >
              <svg
                  aria-hidden="true"
                  class="icon"
              >
                <use xlink:href="#icon-menujiahao"></use>
              </svg>
              添加企业服务
            </button>
          </div>
        </template>
        <template v-slot:thead>
          <tr>
            <th class="text-center">添加时间</th>
            <th class="text-center">项目信息</th>
            <th class="text-center">项目描述</th>
            <th class="text-center">状态</th>
            <th class="text-center">服务提供方</th>
            <th class="text-center">抽成比例</th>
            <th class="text-center">用户查看次数</th>
            <th class="text-center">订单数</th>
            <th class="text-center">签约数</th>
            <th class="text-center">操作</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <template v-if="orderList.length > 0">
            <tr
                v-for="(order, index) in orderList"
                :key="index"
            >
              <td class="text-center date-td">
                {{ order.createTime || '-' }}
              </td>
              <td class="text-center">
                <div
                    class="allow-click"
                    @click="lookEnterpiseService(order.id)"
                >
                  {{ order.name || '-' }}
                </div>
              </td>
              <td class="text-center">
                {{ order.depict || '--' }}
              </td>
              <td class="text-center">
                {{ order.state ? "已上架" : "已下架" }}
              </td>
              <td class="text-center">
                <p>{{ order.providerName || '-' }}</p>
                <p v-if="order.type != 2">
                  {{ order.providerContact || '-' }}
                  【{{ order.providerPhone || '-' }}】
                </p>
              </td>
              <td class="text-center">
                {{ order.commissionScale ? order.commissionScale + '%' : '-' }}
              </td>
              <td class="text-center">
                {{ order.uvCount || '-' }}
              </td>
              <td class="text-center">
                {{ order.orderCount || '-' }}
              </td>
              <td class="text-center">
                {{ order.signCount || '-' }}
              </td>
              <td class="text-center">
                <div class="btn-group">
                  <button
                      aria-expanded="false"
                      aria-haspopup="true"
                      class="btn btn-primary dropdown-toggle"
                      data-toggle="dropdown"
                      type="button"
                  >
                    操作
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <a
                          style="width: 100%;"
                          @click="
                                                        openEditService(order)
                                                    "
                      >
                        修改项目
                      </a>
                    </li>
                    <li>
                      <a
                          style="width: 100%;"
                          @click="
                                                        deleteServiceItem(
                                                            order.id,
                                                            order.state
                                                        )
                                                    "
                      >
                        删除项目
                      </a>
                    </li>
                    <li
                        @click="
                                                    changeEnableState(
                                                        order.id,
                                                        Number(!order.state)
                                                    )
                                                "
                    >
                      <a
                          style="width: 100%;"
                      >{{
                          order.state
                              ? "下"
                              : "上"
                        }}架项目
                      </a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </template>
        </template>
      </CSTable>
      <Pagination/>
    </div>
    <CSDialog
        :dialogVisible="commonModal.visible"
        dialog-confirm-btn-text="确定"
        dialog-header-class=" "
        dialog-header-icon="icon-menua-zu13"
        dialogTitle="提示"
        dialogWidth="480px"
        @onClose="commonModal.onCancel"
        @onConfirm="commonModal.onOk"
    >
      <div
          slot="dialog-content"
          style="padding: 20px 30px; font-size: 24px; text-align: center;"
      >
        {{ commonModal.title }}
      </div>
    </CSDialog>

    <div v-show="!previewModalInfo.visible">
      <CSDialog :dialog-title="dialogTitle" :dialog-visible="meetingRoomModal" dialog-width="1050px"
                @onClose="closeMeetingRoomView"
                @onConfirm="previewModalInfo.visible = true;$vc.copyObject(editMeetingRoom,previewModalInfo.data)"
      >
        <template v-slot:dialog-content>
          <div style="padding: 20px 30px 0 30px;">
            <div class="box_fontsize">
                    <span class="lableBox">服务提供方</span
                    ><i>
                        <span style="margin-right: 20px;">
                            <input
                                v-model="editMeetingRoom.type"
                                :value="MEETING_ROOM_TYPE.PUBLIC"
                                style="margin-right: 10px;"
                                type="radio"
                            />
                            <i class="inputSpace">第三方</i>
                        </span>
              <span>
                            <input
                                v-model="editMeetingRoom.type"
                                :value="MEETING_ROOM_TYPE.PRIVATE"
                                style="margin-right: 10px;"
                                type="radio"
                            />
                            <i class="inputSpace">物业</i>
                        </span>
            </i>
            </div>

            <template
                v-if="editMeetingRoom.type === MEETING_ROOM_TYPE.PUBLIC"
            >
              <div class="box_fontsize">
                <span class="lableBox">商户名称</span>
                <i class="inputwidth">
                  <input
                      v-model="editMeetingRoom.providerName"
                      class="hy-input"
                      placeholder="请输入"
                      type="text"
                  />
                </i>
              </div>
              <div class="box_fontsize">
                <span class="lableBox">联系人</span>
                <i class="inputwidth">
                  <input
                      v-model="editMeetingRoom.providerContact"
                      class="hy-input"
                      placeholder="请输入"
                      type="text"
                  />
                </i>
              </div>
              <div class="box_fontsize">
                <span class="lableBox">手机号【账号】</span>
                <i class="inputwidth">
                  <input
                      v-model="editMeetingRoom.providerPhone"
                      class="hy-input"
                      maxlength="11"
                      placeholder="请输入"
                      type="text"
                  />
                </i>
              </div>
              <div class="box_fontsize">
                <span class="lableBox">抽成比例</span>
                <i class="inputwidth">
                  <CSSelect height="42px" i-width="36px" style="width: 276px">
                    <select
                        v-model="editMeetingRoom.commissionScale"
                        :style="{
                                        color:
                                            editMeetingRoom.commissionScale !=
                                            ''
                                                ? '#000'
                                                : '#999',
                                    }"
                        style="padding-left: 6px;"
                    >
                      <option value="">请选择</option>
                      <template v-for="n in 100">
                        <option
                            v-if="n >= 0"
                            :key="n"
                            :value="n"
                            style="color: #000"
                        >
                          {{ prefixZero(n) }}
                        </option>
                      </template>
                    </select>
                  </CSSelect>
                  %
                </i>
              </div>
            </template>
            <div class="box_fontsize">
              <span class="lableBox">项目名称</span>
              <i class="inputwidth">
                <input
                    v-model="editMeetingRoom.name"
                    class="hy-input"
                    placeholder="请输入"
                    type="text"
                />
              </i>
            </div>
            <div class="box_fontsize">
              <span class="lableBox">项目描述</span>
              <i class="inputwidth">
                <input
                    v-model="editMeetingRoom.depict"
                    class="hy-input"
                    placeholder="请输入"
                    type="text"
                />
              </i>
            </div>

            <div class="box_fontsize" style="margin-top: 10px">
                    <span
                        class="lableBox"
                        style="
                            margin-top: 0;
                            line-height: 1;
                            vertical-align: top;
                        "
                    >项目配图</span
                    >
              <div style="display: inline-block">
                <div
                    v-for="(src, imgIndex) in editMeetingRoom.photos"
                    :key="src"
                    class="photo-view"
                >
                  <img :src="src"/>
                  <i
                      class="icon-close"
                      @click="deletePhotos(imgIndex)"
                  ></i>
                </div>

                <div
                    v-if="editMeetingRoom.photos.length < 5"
                    class="upload-photo"
                    @click="triggerChoosePhoto"
                >
                  <div class="text-center">
                    <img
                        alt=""
                        src="../../assets/upload.png"
                        style="margin: 5px auto 5px"
                    />
                    <p style="color: #999;">上传照片</p>
                  </div>
                </div>
                <input
                    id="uploadStaffPhoto"
                    accept="images/*"
                    class="file"
                    hidden
                    type="file"
                    @change="chooseStaffPhoto($event)"
                />
              </div>
            </div>
            <div class="box_fontsize" style="margin: 30px 0">
                    <span
                        class="lableBox"
                        style="vertical-align: top; margin-top: 0"
                    >
                        项目介绍
                    </span>
              <div
                  class="edit-visiting-modal-content-item-content"
                  style="width: 540px; display: inline-block"
              >
                <WangEditor
                    parentComponent="editVisitingService"
                    style="width: 750px"
                ></WangEditor>
              </div>
            </div>
          </div>
        </template>
      </CSDialog>
    </div>

    <ViewModal></ViewModal>
    <EnterpriseServicePreview
        :data="previewModalInfo.data"
        :visible="previewModalInfo.visible"
        @closeModal="previewModalInfo.onClose"
    >
      <template v-slot:btnGroup>
        <div v-if="meetingRoomModal" class="text-align: center;padding-top: 10px;">
          <button
              class="btn btn-primary mr-5"
              style="width: 180px; height: 40px"
              @click="previewModalInfo.onClose"
          >
            上一步
          </button>
          <!-- addpreviewEditMeetingRoomInfo -->
          <button
              :class="['btn', 'btn-primary', {disabled: isSubmitting}]"
              style="width: 180px; height: 40px"
              @click="commitServiceInfo"
          >
            确认
          </button>
        </div>
        <div v-else class="text-center">
          <button class="btn"
                  style="width: 80px; height: 40px; font-size: 24px; background-color: #1ab394; color: #fff; line-height: 20px; box-shadow: 0px 5px 10px 0px rgba(26, 179, 148, 0.3);"
                  @click="previewModalInfo.onClose"
          >
            关闭
          </button>
        </div>
      </template>
    </EnterpriseServicePreview>

  </div>
</template>

<script>
import ViewModal from "@/components/ViewModal";
import WangEditor from "@/components/WangEditor";
import EnterpriseServicePreview from "@/components/phonePreview/EnterpriseServicePreview";
import Pagination from "@/components/Pagination";
import CSSelect from "@/components/common/CSSelect";

import {
  addItemUrl,
  amendVisitingServiceItemUrl,
  changeEnableEnterpriseServiceItemUrl,
  deleteEnterpriseServiceItemUrl,
  getadminQueryItemsUrl,
  queryEnterpriseServiceItemDetailUrl,
} from "@/requestUrl";
import CSDialog from "@/components/common/CSDialog";
import CSTable from "@/components/common/CSTable";

const MEETING_ROOM_TYPE = {
  PUBLIC: 1,
  PRIVATE: 2,
};
export default {
  components: {
    CSTable,
    CSDialog,
    ViewModal,
    WangEditor,
    EnterpriseServicePreview,
    Pagination,
    CSSelect,
  },
  data() {
    return {
      isSubmitting: false,
      dialogTitle: '添加企业服务',
      MEETING_ROOM_TYPE,
      regionId: this.$vc.getCurrentRegion().communityId,
      refundModalInfo: {
        visible: false,
      },
      // 退款操作谈穿
      commitRefundModal: {
        visible: false,
        orderId: null,
        data: {},
        onClose: () => {
          this.commitRefundModal.visible = false;
        },
        onOk: () => {
          this.commitRefundInfo(this.commitRefundModal.orderId);
        },
      },
      meetingRoomModal: false, //控制添加企业服务弹出框的状态
      orderList: [],
      type: MEETING_ROOM_TYPE.PUBLIC, //必填 1.第三方;2.物业
      orderState: [
        {state: MEETING_ROOM_TYPE.PUBLIC, name: "第三方"},
        {state: MEETING_ROOM_TYPE.PRIVATE, name: "物业"},
      ],
      lesseeCompanyName: "",
      // 添加企业服务里面的数据
      editMeetingRoom: {
        name: "", //项目名称
        regionId: this.$vc.getCurrentRegion().communityId, //必填 区域id
        type: MEETING_ROOM_TYPE.PUBLIC, //必填 1.第三方;2物业
        photos: [], //项目配图
        providerName: "", //商户名称
        providerContact: "", //联系人
        providerPhone: "", //手机号
        commissionScale: "", //抽成比例
        depict: "", //项目描述  必填
        introduction: "", //项目介绍 必填
      },
      state: 1,

      // 预览弹窗相关信息
      previewModalInfo: {
        visible: false,
        data: {},
        onClose: () => {
          this.previewModalInfo.visible = false;
        },
      },
      // 上下架/删除弹窗相关信息
      commonModal: {
        title: "",
        visible: false,
        onCancel: () => {
          this.commonModal.visible = false;
        },
        onOk: () => {
        },
      },
      pageParams: {
        pageNo: 1,
        pageSize: 10
      }
    };
  },
  methods: {
    async lookEnterpiseService(itemId) {
      const res = await this.getEnterpriseServiceInfo(itemId);
      if (res.code != 0) {
        this.$vc.message("获取到服务详细信息失败");
        return;
      }
      console.log(res.data)
      this.previewModalInfo.data = res.data;
      this.previewModalInfo.visible = true;
    },
    deletePhotos(index) {
      this.editMeetingRoom.photos.splice(index, 1);
    },
    // 获取企业服务项目详情
    async getEnterpriseServiceInfo(itemId) {
      return this.$fly
          .post(queryEnterpriseServiceItemDetailUrl, {
            regionId: this.$vc.getCurrentRegion().communityId,
            itemId,
          })
          .then((res) => {
            res.data.photos = JSON.parse(res.data.photos);
            return res;
          });
    },
    // 打开编辑弹窗
    async openEditService(data) {
      const res = await this.getEnterpriseServiceInfo(data.id);
      if (res.code != 0) {
        this.$vc.message("获取到服务详细信息失败");
        return;
      }
      this.$vc.copyObject(res.data, this.editMeetingRoom);
      this.dialogTitle = "修改企业服务"
      this.meetingRoomModal = true;
      this.previewModalInfo.data = res.data;
      console.log("打开编辑弹窗", res.data);
      setTimeout(() => {
        this.$vc.emit(this.$route.path,
            "wangEditor",
            "setWangEditorContent",
            res.data.introduction
        );
      }, 200);
    },
    // 修改富文本编辑器
    changeEditor(html) {
      this.editMeetingRoom.introduction = html;
    },
    // 添加企业服务
    async commitServiceInfo() {
      if (this.isSubmitting) {
        this.$vc.message('请不要重复提交');
        return;
      }
      let params = {...this.editMeetingRoom};
      if (!params.commissionScale && params.type === 1) {
        this.$vc.toast('请选择抽成比例')
        return;
      }
      this.isSubmitting = true;
      if (this.previewModalInfo.data.id) {
        params.id = this.previewModalInfo.data.id;
        params.photos = JSON.stringify(params.photos);
        params.regionId = this.$vc.getCurrentRegion().communityId;
        await this.amendVisitingServiceItem(
            this.$vc.removeNullProperty(params)
        );
      } else {
        await this.addpreviewEditMeetingRoomInfo(
            this.$vc.removeNullProperty(params)
        );
      }
      this.isSubmitting = false;
      this.getMeetingRoomOrders();
    },
    //  获取项目列表信息
    getMeetingRoomOrders(pageNo = this.pageParams.pageNo, pageSize = 10) {
      this.$fly
          .post(getadminQueryItemsUrl, {
            regionId: this.$vc.getCurrentRegion().communityId, //园区id   必填
            pageNo, //页码 选填 为空显示全部
            pageSize, //每页显示条数  选填 默认10
          })
          .then((res) => {
            if (pageNo === 1) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                total: res.data.total,
                currentPage: pageNo,
                pageSize,
              });
            }
            this.orderList = res?.data?.datas || [];
          });
    },
    // 添加企业服务
    addEnterpriseServices() {
      this.editMeetingRoom = {
        name: "", //项目名称
        regionId: this.$vc.getCurrentRegion().communityId, //必填 区域id
        type: MEETING_ROOM_TYPE.PUBLIC, //必填 1.第三方;2物业
        photos: [], //项目配图
        providerName: "", //商户名称
        providerContact: "", //联系人
        providerPhone: "", //手机号
        commissionScale: "", //抽成比例
        depict: "", //项目描述  必填
        introduction: "", //项目介绍 必填
      };
      this.previewModalInfo.data = this.editMeetingRoom;
      this.$vc.emit(this.$route.path, "wangEditor", "setWangEditorContent", "");
      this.meetingRoomModal = true;
      this.dialogTitle = "添加企业服务"
    },
    /**
     * 个位数前缀补零
     * @param {Number} num
     *  */
    prefixZero(num) {
      return num.toString().padStart(2, "0");
    },
    //  调起选择文件
    triggerChoosePhoto() {
      $("#uploadStaffPhoto").trigger("click");
    },
    // 上传图片
    async uploadPhoto(base64) {
      return new Promise((reslove, reject) => {
        this.$vc.http.post(
            "uploadImg",
            "upload",
            {
              img: base64,
            },
            {
              headres: {
                "Content-Type": "application/json",
              },
            },
            (resStr, res) => {
              reslove(res.body.fileSaveName);
            },
            (errText, err) => {
              reject(errText);
            }
        );
      });
    },
    // 选择文件后处理
    async chooseStaffPhoto(event) {
      var photoFiles = event.target.files;
      if (photoFiles && photoFiles.length > 0) {
        // 获取目前上传的文件
        var file = photoFiles[0]; // 文件大小校验的动作
        if (file.size > 1024 * 1024 * 2) {
          this.$vc.toast("图片大小不能超过 2MB!");
          return false;
        }
        let formData = new FormData(); //新建FormData对象
        formData.append("files", photoFiles[0]);
        formData.append("bucketName", this.Constants.BUCKET_NAMES.IMG.name);
        formData.append("compress", "true");
        const photo = await this.Constants.uploadFileOne(formData);
        if (photo) {
          this.editMeetingRoom.photos.push(this.Constants.BUCKET_NAMES.IMG.visitUrl + photo);
        }
      }
    },

    // 提交添加的企业服务
    addpreviewEditMeetingRoomInfo(params) {
      params.photos = JSON.stringify(params.photos);
      return this.$fly.post(addItemUrl, params).then((res) => {

        if (res.code != 0) {
          return;
        }
        this.$vc.toast("操作成功");
        this.closeMeetingRoomView();
        this.getMeetingRoomOrders();
        return;
      })
          .catch(err => err);
    },

    // 关闭预览弹窗
    closeMeetingRoomView() {
      this.previewModalInfo.onClose();
      this.meetingRoomModal = false;
    },
    /**
     * 编辑上门服务
     * @param {Object} data 上门服务信息
     *  */
    amendVisitingServiceItem(data) {
      return this.$fly.post(amendVisitingServiceItemUrl, data).then((res) => {
        if (res.code != 0) {
          return;
        }
        this.$vc.toast("修改成功");
        this.getMeetingRoomOrders();
        this.closeMeetingRoomView();
        return;
      })
          .catch(err => err);
    },
    /**
     * 修改上下架状态
     * @param {Number} id 项目id
     * @param {Number} state 当前项目的上下架状态  0:下架 1:上架
     *  */
    changeEnableState(id, state) {
      this.commonModal.visible = true;
      this.commonModal.title = `确定${state ? "上" : "下"}架吗?`;
      this.commonModal.onOk = () => {
        this.$fly
            .post(changeEnableEnterpriseServiceItemUrl, {
              regionId: this.regionId,
              id,
              state,
            })
            .then((res) => {
              if (res.code != 0) {
                return;
              }
              this.commonModal.visible = false;
              this.getMeetingRoomOrders();
              this.$vc.toast(`${state ? "上" : "下"}架成功`);
            });
      };
    },
    /**
     * 删除项目
     * @param {Number} id 项目id
     *  */
    deleteServiceItem(id) {
      this.commonModal.visible = true;
      this.commonModal.title = `确定删除吗?`;
      this.commonModal.onOk = () => {
        this.$fly
            .post(deleteEnterpriseServiceItemUrl, {
              regionId: this.regionId,
              id,
            })
            .then((res) => {
              if (res.code != 0) {
                return;
              }
              this.commonModal.visible = false;
              this.getMeetingRoomOrders();
              this.$vc.toast("删除成功");
            });
      };
    },
  },
  mounted() {
    this.getMeetingRoomOrders();
    this.$vc.on(this.$route.path, "editVisitingService", "changeEditor", this.changeEditor);
    this.$vc.on(this.$route.path, "pagination", "page_event", (_currentPage) => {
      this.pageParams.pageNo = _currentPage;
      this.getMeetingRoomOrders(_currentPage);
    });
  },
};
</script>

<style scoped>
#EnterpriseServices .el-dialog__header {
  padding: 0;
  height: 0;
}

#EnterpriseServices .el-dialog__body {
  padding: 20px 0 20px 30px;
}

#EnterpriseServices .el-dialog__footer {
  padding-top: 20px;
  text-align: center;
  border-top: 1px solid #999;
}

.signBox > el-dialog__footer {
  border-top: 1px solid #999;
}

.el-dialog .cancel {
  width: 80px;
  height: 40px;
  background: #ff9f00;
  color: #fff;
  font-size: 20px;
  box-shadow: 0px 5px 10px 0px rgba(255, 159, 0, 0.3);
  border-radius: 6px;
  padding: inherit;
  margin-right: 30px;
}

.el-dialog .confirm {
  width: 80px;
  height: 40px;
  color: #fff;
  background: #1ab394;
  font-size: 20px;
  padding: inherit;
  box-shadow: 0px 5px 10px 0px rgba(0, 182, 148, 0.3);
  border-radius: 6px;
}

#EnterpriseServices .el-card__body {
  padding: 3px 0 0px 0;
}

.addEnterpriseService {
  float: right;
  margin-bottom: 10px;
  color: #fff;
  width: 150px;
  height: 40px;
  background: #1ab394;
  box-shadow: 0px 5px 10px 0px rgba(0, 182, 148, 0.3);
  border: none;
  border-radius: 4px;
}
</style>

<style lang="stylus" scoped>
.inputwidth
  input
    &::placeholder
      color #999
    border-radius 4px
    border 1px solid #979797

i
  font-style normal

/* 项目管理模块样式 */
.addVisitingService
  width 120px
  height 30px
  background-color #1ab394
  color #fff
  vertical-align middle
  padding 7px 0 15px 25px
  margin-bottom 15px
  border-radius 6px
  margin-right 20px
  box-shadow 0px 5px 10px 0px rgba(0, 182, 148, 0.3)
  position relative

.el-icon-plus
  position absolute
  top 7px
  left 7px
  font-weight 900
  font-size 14px
  margin-right 5px
  border-radius 15px

#servebox .box_body .el-card__body
  padding 0px !important

.nav .el-card__body
  padding 0px !important

.table-stripped thead
  border-top 1px solid #dddddd
  height 50px !important

.text-center
  padding 15px 0

.el-button .el-button--default
  margin-right 0 !important

.lableBox
  display inline-block
  width 168px
  margin 10px 0
  text-align right
  margin-right 30px
  color #000

.input[type='radio']
  width 18px
  height 18px
  cursor pointer
  position relative
  outline none

.cs-dialog
  input[type='radio']
    cursor pointer
    position relative
    outline none
    margin-right 5px

.inputSpace
  margin-right 10px
  color #000

.cancel
  width 80px
  height 40px
  background #ff9f00
  box-shadow 0px 5px 10px 0px rgba(255, 159, 0, 0.3)
  border-radius 6px
  color #fff
  border none
  font-size 24px
  margin-right 30px

.determine
  width 80px
  height 40px
  background #1ab394
  box-shadow 0px 5px 10px 0px rgba(0, 182, 148, 0.3)
  border-radius 6px
  color #fff
  border none
  font-size 24px

#EnterpriseServices .el-dialog__footer
  border none

.cancel
  width 80px
  height 40px
  background #ff9f00
  box-shadow 0px 5px 10px 0px rgba(255, 159, 0, 0.3)
  border-radius 6px
  color #fff
  border none
  margin-right 30px

.determine
  width 80px
  height 40px
  background #1ab394
  box-shadow 0px 5px 10px 0px rgba(0, 182, 148, 0.3)
  border-radius 6px
  color #fff
  border none
  font-size 20px

#EnterpriseServices .el-dialog__footer
  border none

.SecondPrompt
  font-size 20px
  text-align center

.result-panel table td
  vertical-align middle

.result-panel
  padding-bottom 0

.result-panel table p
  margin-bottom 0

.box_fontsize
  font-size 24px

.cancel
  width 80px
  height 40px
  background #FF9F00
  box-shadow 0px 5px 10px 0px rgba(255, 159, 0, 0.3)
  border-radius 6px
  font-size 24px

.preview
  width 80px
  height 40px
  background #1ab394
  box-shadow 0px 5px 10px 0px rgba(0, 182, 148, 0.3)
  border-radius 6px
  border none
  color #fff
  font-size 24px

.hy-input
  padding-left 10px

input::-webkit-input-placeholder
  color: #999;

input::-moz-placeholder
  color: #999;

input::-moz-placeholder
  color: #999;

input::-ms-input-placeholder
  color: #999;
</style>

<template>
    <div class="preview-layer" v-if="visible" @click="closePreviewModal">
        <div class="preview-view" @click.stop="() => {}">
            <RealPhonePreview>
                <template v-slot:preview>

                    <div class="rich-text-panel">
                        <el-carousel height="220px" arrow="never">
                            <el-carousel-item style="width: 100%;text-align: center;padding: 0;" v-for="(url, index) in data.photos" :key="index">
                                <img style="height: 100%;width: 100%;" :src="url"  alt="">
                            </el-carousel-item>
                        </el-carousel>
                        <div class="title">
                            <div class="title-top">
                                <div class="official">{{data.type === 1 ? '第三方' : '官方'}}</div>
                                <div class="name">{{data.name}}</div>
                            </div>
                            <div class="title-bottom">
                                <div class="describe">
                                    <div class="line"></div>
                                    <div class="tips">服务描述</div>
                                </div>
                                <div class="info">{{data.depict}}</div>
                            </div>
                        </div>
                        <div class="introduce">
                            <div class="describe">
                                <div class="line"></div>
                                <div class="tips">服务描述</div>
                            </div>
                            <div v-html="data.introduction" style="text-align:center;"></div>

                        </div>
                        <div class="btn" >立即询价</div>
                    </div>
                </template>
            </RealPhonePreview>
            <slot name="btnGroup"></slot>
        </div>
    </div>
</template>

<script>
import RealPhonePreview from "@/components/RealPhonePreview";

export default {
    name: "enterpriseServicePreview",
    components: {
        RealPhonePreview,
    },
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        data: Object,
    },
    data() {
        return {};
    },
    methods: {
        // 关闭弹窗
        closePreviewModal() {
            this.$emit("closeModal");
        },
    },
    mounted() {},
};
</script>
<style lang="stylus" scoped>
.rich-text-panel
    width 100%
    height 100%
     //position relative
    background #f0f0f0
    div
        padding calc((20px / 2.5))
    .btn
        position sticky
        left 0
        bottom 0
        width 100%
        right 0
        background var(--theme-color)
        color #fff
        text-align center
        padding 0
        line-height calc((80px / 2.5))
        font-size calc((36px / 2.5))
.title
    background-color #fff
    width 95%
    margin 0 auto
    border-radius 5px
    .title-top
        display flex
        align-items center
        padding 0 0 10px 5px
        border-bottom 1px solid #f0f0f0
        .official
            min-width 40px
            height 20px
            background-color rgba(255, 230, 230, 1)
            color: rgba(255, 90, 90, 1);
            text-align center
            border-radius 5px
            padding 0 3px
            font-size 14px
            line-height 20px
            margin-right 10px
        .name
            padding 0
            font-weight 700
            color #000
            font-size 16px
    .title-bottom
        padding-bottom 0
        .describe
            display flex
            align-items center
            padding 0
            .line
                width 5px
                height 15px
                background-color #FF5A5A
                padding 0
                margin-right 10px
            .tips
                font-size 16px
                font-weight 500
                padding 0
                color #000
        .info
            padding-left 0
            color #999

.introduce
    margin 0 auto
    margin-top 10px
    background-color #fff
    border-radius 5px
    width 95%
    margin-bottom calc((80px / 2.5))
    .describe
        display flex
        align-items center
        padding 0
        .line
            width 5px
            height 15px
            background-color #FF5A5A
            padding 0
            margin-right 10px
        .tips
            font-size 16px
            font-weight 500
            padding 0
            color #000
    img
        width 100%
</style>
